.details-back {
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: rgb(24, 29, 49);
}

.rate-card {
  position: relative;
  overflow: hidden;
  background: linear-gradient(-90deg, #815eba 60%, #4c4c70 100%);
}

.rate-card[rarity="5"] {
  background: linear-gradient(
    -90deg,
    rgba(190, 149, 108, 1) 60%,
    rgba(147, 92, 89, 1) 100%
  );
}

.card-char-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-44%, -20%);
}

.rate-card-name {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(255, 255, 255, 0) 70%
  );
  color: white;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

.rate-card-path {
  position: absolute;
  filter: brightness(3);
  opacity: 0.3;
  z-index: 0;
}

.card-weap-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(120%, -65%);
  width: 18%;
  rotate: 13deg;
}

.card-glass-back {
  transform: translate(125%, -61%);
}

.card-glass-front {
  transform: translate(115%, -70%);
}

.details-stars {
  display: flex;
}

@media screen and (max-width: 600px) {
  .line-thing {
    display: none;
  }
  .details-stars[hide="true"] {
    display: none !important;
  }
}
