.App {
  text-align: center;
  overflow: hidden;
}

* {
  cursor: url(../assets/cursor.cur), auto !important;
  user-select: none;
}

body * {
  font-family: Default_Font;
  letter-spacing: -0.5px;
}

@font-face {
  font-family: Default_Font;
  src: url(../assets/font.woff2);
  font-display: swap;
}

.abs-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
}

#banner-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  z-index: 0;
}

.banner {
  top: 50%;
  left: 50%;
  position: absolute;
}

#loading {
  background-color: #282827;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#audio-toggle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  transform: translate(-30%, 30%);
}

.skip-button {
  position: absolute;
  z-index: 1000;
  top: 3%;
  right: 1%;
  width: 85px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #8084882a;
  transition: border 0.2s;
}

.skip-button:hover {
  border: none;
}

.skip-icon {
  filter: invert(60%) sepia(7%) saturate(250%) hue-rotate(169deg)
    brightness(86%) contrast(82%);
  transition: filter 0.2s;
}

.skip-button:active .skip-icon {
  filter: invert(90%) sepia(20%) saturate(596%) hue-rotate(3deg) brightness(92%)
    contrast(86%);
}

.custom-button {
  background-color: #eae9ee;
  border-radius: 50px;
  text-align: center;
  transition: filter 0.2s, box-shadow 0.2s, background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
}

.custom-button.disabled {
  filter: brightness(0.4);
}

.button-outline {
  border-radius: 50px;
  border: 2px solid #d2d1d6;
  transition: border-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text {
  position: relative;
  font-weight: bold;
}

.custom-button:hover {
  filter: brightness(1.1);
  box-shadow: 0 0 0 2px #d2d2d2, 0 0 0 5px white;
}

.custom-button:active {
  background-color: #d2d2d6;
  box-shadow: 0 0 0 2px #adabaf, 0 0 0 5px white;
}

.custom-button:active .button-outline {
  border-color: #b8b8bc;
}

.custom-button.disabled:hover {
  filter: brightness(0.4);
}

.custom-button.disabled:active {
  filter: brightness(0.4);
}

.country-button {
  margin: 5px;
}

.close-button {
  position: absolute;
  scale: 0.4;
  right: 1.5%;
  top: 1.5%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-leg {
  background-color: whitesmoke;
  transition: transform 0.2s, scale 0.2s;
}

#button-dot {
  background-color: whitesmoke;
  border-radius: 100%;
  transition: scale 0.2s;
}

.close-button:hover > div > div {
  background-color: #dcc491 !important;
}

.close-button:hover > div > .top-left {
  transform: translate(-60%, -12%) rotate(-45deg);
}

.close-button:hover > div > .top-right {
  transform: translate(60%, -12%) rotate(45deg);
}

.close-button:hover > div > .bottom-left {
  transform: translate(60%, 12%) rotate(-45deg);
}

.close-button:hover > div > .bottom-right {
  transform: translate(-60%, 12%) rotate(45deg);
}

.close-button:hover > div > #button-dot {
  scale: 1.2;
}

.close-button:active > div > #button-dot {
  scale: 1;
}

.close-button:active > div > .top-left {
  transform: translate(120%, 24%) rotate(-45deg);
}

.close-button:active > div > .top-right {
  transform: translate(-120%, 24%) rotate(45deg);
}

.close-button:active > div > .bottom-left {
  transform: translate(-120%, -24%) rotate(-45deg);
}

.close-button:active > div > .bottom-right {
  transform: translate(120%, -24%) rotate(45deg);
}

.button-leg.top-left {
  transform: rotate(-45deg);
}

.button-leg.top-right {
  transform: rotate(45deg);
}

.button-leg.bottom-left {
  transform: rotate(-45deg);
}

.button-leg.bottom-right {
  transform: rotate(45deg);
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-38%, -55%);
  will-change: transform;
  animation: ring-spin 60s infinite linear;
}

@keyframes ring-spin {
  to {
    transform: translate(-38%, -55%) rotate(-360deg);
  }
}

#info {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(5%, 60%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1000;
  text-shadow: 0 0 4px black;
}

#warp-icon {
  background-position: center;
  background-repeat: no-repeat;
}

#title {
  color: #dcc491;
}

#warp-type {
  color: #e9e9eb;
}

.mini-banners {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1000;
  height: 100%;
}

.mini-banner-img-active {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 0.4s;
}

.mini-banner-img-active[active="false"] {
  opacity: 0;
  z-index: -1;
}

.mini-banner-img-active[active="true"] {
  opacity: 1;
  z-index: 3;
}

.shine-container {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
}

.shine {
  height: 100%;
  width: 50%;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  animation: animate-shine 0.5s forwards 1;
}

@keyframes animate-shine {
  0% {
    transform: translateX(-50%) skew(-30deg);
    opacity: 1;
  }
  100% {
    transform: translateX(200%) skew(-30deg) scaleX(0.5);
    opacity: 0.2;
  }
}

.mini-banner-img {
  filter: brightness(0.7);
  transition: filter 0.2s, opacity 0.4s;
  z-index: 0;
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 0;
}

.mini-banner-highlight {
  opacity: 0;
  transition: filter 0.2s, opacity 0.2s;
  z-index: 200;
  pointer-events: none;
  position: absolute;
  top: 30%;
  left: 0;
}

.mini-banner-img[active="false"] {
  opacity: 1;
}

.mini-banner-img[active="true"] {
  opacity: 0;
}

.mini-banner-img:hover {
  filter: brightness(1);
}

.mini-banner-img:active {
  filter: brightness(0.7);
}

.mini-mini-banners {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1000;
  height: 100%;
}

.mini-mini-banner-img {
  filter: brightness(0.7);
  transition: filter 0.2s, opacity 0.4s;
  z-index: 0;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.mini-mini-banner-img[active="false"] {
  opacity: 1;
}

.mini-mini-banner-img[active="true"] {
  opacity: 0;
}

.mini-mini-banner-img:hover {
  filter: brightness(1);
}

.mini-mini-banner-img:active {
  filter: brightness(0.7);
}

.mini-mini-banner-highlight {
  opacity: 0;
  transition: filter 0.2s, opacity 0.2s;
  z-index: 200;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

#main-back {
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  transition: background-color 0.2s ease;
}

#main-back-back {
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

#main-back-cover {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(156, 156, 156, 0.199);
}

#exchange-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-310%, 850%);
  z-index: 1000;
  filter: brightness(0.4);
}

#view-details-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-205%, 850%);
  z-index: 1000;
  filter: brightness(0.4);
}

.warp-button {
  position: absolute;
  bottom: -1.5%;
  right: 0;
  z-index: 100;
  transition: filter 0.2s;
}

.warp-button.one {
  right: 26%;
}

.warp-button.ten {
  right: 1.5%;
}

.warp-button:active {
  filter: brightness(0.8);
}

#video-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1051;
}

#video-container video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* Settings */

#settings-button {
  position: absolute;
  z-index: 1000;
  right: 1.5%;
  top: 1.5%;
  transition: filter 0.1s, scale 0.2s;
  filter: grayscale(1) brightness(2);
}

#settings-button:hover {
  filter: grayscale(1) brightness(2.2);
  box-shadow: 0 0 10px 2px white;
}

#settings-button:active {
  filter: grayscale(0) brightness(1.1);
  scale: 0.9;
}

.menu-button {
  transition: filter 0.2s;
}

.menu-button.disabled {
  filter: brightness(0.5);
}

.menu-button.disabled:hover {
  filter: brightness(0.5);
}

.menu-button.disabled:active {
  filter: brightness(0.5);
}

.menu-button:hover {
  filter: brightness(1.5);
}

.menu-button:active {
  filter: brightness(2);
}

.vers-info {
  margin-bottom: 1%;
  border: 4px solid #cabdaa8c;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  transition: background-color 0.2s;
  min-height: 130px;
  max-height: fit-content;
  width: 95%;
  padding: 4px 0;
}

.vers-info:hover {
  background-color: #1b1b1b25;
}

.vers-info:active {
  background-color: #4949496c;
}

.vers-info * {
  transition: color 0.2s, border-color 0.2s;
}

.vers-info.highlight {
  background-color: #282827;
  border-color: #c2ab89;
}

.vers-info-text {
  display: flex;
  flex-direction: column;
}

/* Warp Single */

.transparent {
  visibility: hidden;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1051;
  background-size: 100vw 100vh;
}

.single-item {
  position: absolute;
  top: 50%;
  left: 50%;
  scale: 0.8;
  z-index: 9;
  transform-origin: top left;
}

.single-item.char {
  transform: translate(-50%, -50%);
  animation: animate-single-char 0.6s forwards;
}

@keyframes animate-single-char {
  0% {
    scale: 1;
    filter: brightness(0.5);
  }
  67% {
    scale: 0.8;
  }
  100% {
    scale: 0.8;
    filter: brightness(1);
  }
}

.single-item.weap {
  transform: translate(-27%, -60%);
  animation: animate-single-weap 0.6s forwards;
  opacity: 1;
}

@keyframes animate-single-weap {
  0% {
    scale: 1.4;
    transform: translate(-40%, -80%);
  }
  100% {
    scale: 0.8;
  }
}

.weap {
  rotate: 7deg;
}

.glass {
  rotate: 7deg;
  position: absolute;
  top: 50%;
  left: 50%;
}

.glass.back {
  transform: translate(-44%, -59%);
  animation: animate-glass-back 0.7s forwards;
  z-index: 9;
}

@keyframes animate-glass-back {
  0% {
    scale: 1.3;
    filter: brightness(0.5);
    transform: translate(15%, -20%);
    opacity: 0;
  }
  67% {
    scale: 0.8;
  }
  100% {
    scale: 0.8;
    filter: brightness(1);
  }
}

.glass.front {
  transform: translate(-56%, -71%);
  animation: animate-glass-front 0.7s forwards;
  z-index: 9;
}

@keyframes animate-glass-front {
  0% {
    scale: 1.4;
    filter: brightness(0.5);
    transform: translate(-75%, -125%);
  }
  67% {
    scale: 0.8;
  }
  100% {
    scale: 0.8;
    filter: brightness(1);
  }
}

#single-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
}

@keyframes animate-info {
  0% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(-100%, -50%);
  }
}

.single-info-shadow {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.path-shadow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #d0d5cf;
}

#info-pair {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  white-space: nowrap;
}

.single-name {
  word-wrap: break-word;
  user-select: none;
  text-shadow: 0 0 4px black;
}

.animation-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation: animate-animation-ring 1.8s forwards;
  animation-timing-function: cubic-bezier(0.58, 0.16, 0.19, 0.91);
  filter: invert(100%) sepia(62%) saturate(7041%) hue-rotate(171deg)
    brightness(105%) contrast(98%);
}

.animation-ring[rarity="5"] {
  filter: invert(89%) sepia(5%) saturate(1652%) hue-rotate(334deg)
    brightness(116%) contrast(91%);
}

.animation-ring[rarity="4"] {
  filter: invert(90%) sepia(89%) saturate(513%) hue-rotate(192deg)
    brightness(101%) contrast(97%);
}

@keyframes animate-animation-ring {
  0% {
    scale: 0.6;
    opacity: 0.4;
  }
  33% {
    scale: 2;
    opacity: 1;
  }
  100% {
    scale: 4;
    rotate: -70deg;
  }
}

.light-ray {
  width: 500vw;
  height: 500vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation: animate-light-ray 1.8s forwards;
  animation-timing-function: cubic-bezier(0.58, 0.16, 0.19, 0.91);
  filter: invert(100%) sepia(62%) saturate(7041%) hue-rotate(171deg)
    brightness(105%) contrast(98%);
}

.light-ray[rarity="5"] {
  filter: invert(89%) sepia(5%) saturate(1652%) hue-rotate(334deg)
    brightness(116%) contrast(91%);
}

.light-ray[rarity="4"] {
  filter: invert(90%) sepia(89%) saturate(513%) hue-rotate(192deg)
    brightness(101%) contrast(97%);
}

@keyframes animate-light-ray {
  0% {
    opacity: 0.4;
  }
  33% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    rotate: 40deg;
  }
}

.donut {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  z-index: 3;
  transform: translate(-50%, -50%);
  border: 280px solid #5280db3f;
  border-radius: 100%;
  height: 1100px;
  width: 1100px;
  animation: animate-animation-ring 1.8s forwards;
}
.donut[rarity="4"] {
  border-color: #5541ac49;
}
.donut[rarity="5"] {
  border-color: #c49e4c2f;
}

/* Warp Results */

#warp-result-container {
  background-color: #1d2b45;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.warp-results {
  display: flex;
  flex-direction: row;
  transform: rotate(-11.5deg);
  width: 110vw;
  justify-content: center;
  align-content: center;
}

.warp-results.top {
  margin-bottom: 2%;
  justify-content: flex-start;
}

.warp-results.bottom {
  margin-top: 2%;
  justify-content: flex-end;
}

.warp-results-new-tag {
  position: absolute;
  right: 0;
  transform: translateY(80%);
}

.warp-result-hover {
  transition: scale 0.3s;
  display: flex;
  position: relative;
}

.warp-result.five-back {
  position: absolute;
  z-index: 1;
}

.warp-result-hover:hover {
  scale: 1.05;
}

.warp-result {
  transition: opacity 0.2s;
}

.warp-result-hover[rarity="3"] {
  outline: 2px solid #182c50;
  border-bottom: 0;
  box-shadow: 0 0 10px 1px #213e77;
}

.warp-result-hover[rarity="4"] {
  box-shadow: 0 0 50px 10px #a87cff;
}

.warp-result-hover[rarity="5"] {
  box-shadow: 0 0 50px 10px #fbda92;
}

.hack-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

@keyframes animate-three {
  0% {
    transform: translateX(-200vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes animate-three-middle {
  0% {
    transform: translateX(200vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animate-result {
  0% {
    transform: translateX(-200vw);
  }
  50% {
    filter: brightness(1.5);
  }
  100% {
    transform: translate(0);
    filter: brightness(1);
  }
}
@keyframes animate-result-middle {
  0% {
    transform: translateX(200vw);
  }
  50% {
    filter: brightness(1.5);
  }
  100% {
    transform: translate(0);
    filter: brightness(1);
  }
}

@keyframes animate-five-back {
  0% {
    rotate: 0deg;
  }
  25% {
    rotate: -1deg;
  }
  50% {
    rotate: 0deg;
  }
  75% {
    rotate: 1deg;
  }
  100% {
    rotate: 0deg;
  }
}

@keyframes animate-change-out {
  to {
    opacity: 0;
  }
}

@keyframes animate-change-in {
  to {
    opacity: 1;
  }
}

.five-special-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(at bottom, #162342, #01040c, black);
}

.reveal-path {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  opacity: 0.5;
  filter: brightness(0.5);
  transform-origin: top left;
  width: 25%;
}

.reveal-star-group {
  position: absolute;
  top: 50%;
  left: 50%;
}

.reveal-star-back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200vh;
  width: 60px;
  background-color: #3c506e5b;
}

.reveal-star-back.main {
  width: 150px;
}

.reveal-star {
  width: 80%;
  scale: 0.6;
}

.reveal-star.main {
  scale: 1;
}

@keyframes animate-reveal-path {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes animate-reveal-back {
  from {
    transform: translate(-50%, -50%) scaleX(1);
  }
  to {
    transform: translate(-50%, -50%) scaleX(0);
  }
}

@keyframes animate-reveal-star {
  50% {
    filter: brightness(3);
  }
  100% {
    filter: brightness(1);
  }
}

@keyframes animate-reveal-first-group {
  from {
    transform: translate(-190%, 100%);
  }
  to {
    transform: translate(-190%, -50%);
  }
}

@keyframes animate-reveal-second-group {
  from {
    transform: translate(-120%, 100%);
  }
  to {
    transform: translate(-120%, -50%);
  }
}

@keyframes animate-reveal-third-group {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes animate-reveal-fourth-group {
  from {
    transform: translate(20%, 100%);
  }
  to {
    transform: translate(20%, -50%);
  }
}

@keyframes animate-reveal-fifth-group {
  from {
    transform: translate(90%, 100%);
  }
  to {
    transform: translate(90%, -50%);
  }
}

@keyframes animate-out-path {
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0;
    scale: 0.5;
  }
}

@keyframes animate-out-star {
  from {
    scale: 1;
  }
  to {
    scale: 0.5;
  }
}

@keyframes animate-out-small-star {
  from {
    scale: 0.6;
  }
  to {
    scale: 0.3;
  }
}

.checkbox {
  background-color: white;
  width: 100px;
  font-size: 20px;
}

@media screen and (max-width: 1280px) {
  .custom-button {
    line-height: 80%;
  }
}

@media screen and (max-width: 600px) {
  #banner-container[rerun="true"] {
    transform: translate(-50%, -25%);
  }

  .reveal-path {
    width: 50%;
  }

  .reveal-star-back {
    width: 30px;
  }

  .reveal-star-back.main {
    width: 75px;
  }

  .reveal-star {
    width: 60%;
  }

  @keyframes animate-reveal-first-group {
    from {
      transform: translate(-140%, 100%);
    }
    to {
      transform: translate(-140%, -50%);
    }
  }

  @keyframes animate-reveal-second-group {
    from {
      transform: translate(-95%, 100%);
    }
    to {
      transform: translate(-95%, -50%);
    }
  }

  @keyframes animate-reveal-third-group {
    from {
      transform: translate(-50%, 100%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }

  @keyframes animate-reveal-fourth-group {
    from {
      transform: translate(-5%, 100%);
    }
    to {
      transform: translate(-5%, -50%);
    }
  }

  @keyframes animate-reveal-fifth-group {
    from {
      transform: translate(40%, 100%);
    }
    to {
      transform: translate(40%, -50%);
    }
  }
}

@media screen and (max-width: 500px) {
  .custom-button {
    line-height: 50%;
  }

  .vers-info {
    font-size: 14px;
  }

  .vers-icon {
    width: 100px;
  }

  @keyframes animate-animation-ring {
    0% {
      scale: 0.2;
      opacity: 0.4;
    }
    33% {
      scale: 0.8;
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      scale: 1.6;
      rotate: 0.5turn;
      opacity: 0;
    }
  }

  .donut {
    border-width: 200px;
    height: 800px;
    width: 800px;
  }

  .country-button {
    margin: 10px;
  }

  .country-icon {
    width: 16px;
    height: 16px;
  }

  .country-name {
    display: none;
  }

  .close-button {
    top: 0%;
    right: 1%;
  }

  .checkbox {
    width: 70px;
    font-size: 12px;
  }

  .warp-result {
    width: 90px;
    height: 50px;
  }

  .skip-button {
    width: 60px;
    height: 30px;
  }

  .skip-icon {
    width: 15px;
  }
}

@media screen and (max-height: 400px) and (orientation: landscape) {
  #main-back {
    overflow: auto;
  }
}
